$(document).ready(function(){

   $('.header__carousel').slick({
        adaptiveHeight: true,
        dots: true,
        fade: true,
        draggable: true,
        swipe: true,
        swipeToSlide: true,
        touchMove: true,
        arrows: false,
        appendDots: $('.mobile__pagination'),
        autoplay: true,
        autoplaySpeed: 3000
    });

   $('.header__carousel').on('afterChange', function(event, slick, currentSlide, nextSlide){
       $(slick.$slides.get(currentSlide)).closest('li').addClass('slick-active');
   });

    var $status = $('.total_docs');

    $('.quality__slider').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        $status.html('<span class="curr">' + pad(i) + '</span>/<span class="tot">' + pad(slick.slideCount) + '</span>');
    });

     $('.quality__slider').slick({
        adaptiveHeight: true,
        dots: false,
        rows: 2,
        slidesPerRow: 2,
        appendArrows: $('.quality__slider-nav'),
        draggable: true,
        swipe: true,
        swipeToSlide: true,
        touchMove: true,
        prevArrow: '<i class="fa fa-angle-left fa-4x" aria-hidden="true"></i>',
        nextArrow: '<i class="fa fa-angle-right fa-4x" aria-hidden="true"></i>'
    });

    function pad(n) {
        return (n < 10) ? ("0" + n) : n;
    }

    $('.form').submit(function(e) { //устанавливаем событие отправки для формы с id=form
        e.preventDefault();
        var form_data = $(this).serialize(); //собераем все данные из формы
        $.ajax({
            type: 'POST', //Метод отправки
            url: 'mail.php', //путь до php фаила отправителя
            data: form_data,
            success: function() {
                //код в этом блоке выполняется при успешной отправке сообщения
                console.log('Ваше сообщение отпрвлено!');
            }
        });
    });

});

$(document).ready(function() {
    // Show or hide the sticky footer button
    $(window).scroll(function() {
        if ($(this).scrollTop() > 200) {
            $('.go-top').fadeIn(200);
        } else {
            $('.go-top').fadeOut(200);
        }
    });

    // Animate the scroll to top
    $('.go-top').click(function(event) {
        event.preventDefault();

        $('html, body').animate({scrollTop: 0}, 300);
    });

    var $root = $('html, body');

    $('a[href^="#"]').click(function() {
        var href = $(this).attr('href');

        $root.animate({
            scrollTop: $(href).offset().top
        }, 500, function () {
            window.location.hash = href;
        });

        return false;
    });

    $('.navbar-toggle').on('click', function() {
        if(!$('.header__inner').hasClass('menu__opened')) {
            setTimeout(function() {
                $('.header__inner').addClass('menu__opened');
            }, 350);
        } else {
            $('.header__inner').removeClass('menu__opened');
        }
    });

    $('#phone-input').mask('(999)-999-9999');
});